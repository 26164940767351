import React from 'react';
import Header from './header';

class Layout extends React.Component {
  render() {
    const { children } = this.props;

    return (
      <div>
        {children}
      </div>
    )
  }
}

export default Layout;
