import React from 'react';
import { graphql } from 'gatsby';
import Layout from '@site/layouts';
import { IMarkdownRemark } from '@site/interface';
import MainContent from '@site/components/content/main-content';

interface IProps {
  data: {
    markdownRemark: IMarkdownRemark;
  }
}

const MainTemplate: React.FC<IProps> = (props) => {
  const { data, ...rest } = props;
  const { markdownRemark } = data;
  const { frontmatter, fields, html } = markdownRemark;

  return (
    <Layout {...rest}>
      {markdownRemark.html}
      <MainContent
        {...rest}
        pageData={{
          meta: {
            ...frontmatter,
            ...fields,
            filename: fields.slug,
            path: fields.path,
          },
          content: html
        }}
      />
    </Layout>
  )
};

export default MainTemplate;

export const pageQuery = graphql`
  query TemplateMainMarkdown($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      tableOfContents(maxDepth: 2)
      frontmatter {
        order
        title
      }
      fields {
        path
        slug
        modifiedTime
      }
    }
  }
`;
