import React from 'react';
import { Location } from 'history';
import SEO from '@site/components/seo';
import { IFrontMatter } from '@site/interface';
import Article from './article';

export interface IMainContentProps {
  isMobile?: boolean;
  location?: Location;
  pageData: {
    meta: IFrontMatter;
    content: string;
  };
}

class MainContent extends React.Component<IMainContentProps> {
  render() {
    const { pageData } = this.props;
    const { meta, content } = pageData;

    return (
      <div>
        <SEO title={meta.title} />
        <Article meta={meta} content={content} />
      </div>
    )
  }
}

export default MainContent;
